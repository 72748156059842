<template>
	<v-navigation-drawer :value="opened" fixed app color="sidebarBackground" @input="toogle">
		<div class="d-flex align-center px-10 py-2">
			<div class="flex-shrink-1">
				<v-btn plain fab :ripple="false" active-class="" :to="{ name: 'Landing' }">
					<v-avatar size="48" class="mr-4">
						<v-img src="@/assets/images/icon/logo-oversos-second-1x.png" />
					</v-avatar>
				</v-btn>
			</div>
			<span class="text-h5" style="font-family: Tahoma">OverSOS</span>
		</div>

		<v-footer padless fixed absolute color="sidebarBackground">
			<v-row justify="center" no-gutters>
				<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Contact' }">
					{{ $t('nav.contactUs') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'FAQ' }">
					{{ $t('nav.faq') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Team' }">
					{{ $t('nav.team') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Policies' }">
					{{ $t('policies.title') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" style="font-size: 0.5rem" :to="{ name: 'Pricing' }">
					{{ $t('nav.pricing') }}
				</v-btn>
			</v-row>
			<v-row justify="center" no-gutters>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://instagram.com/oversos.official" target="_blank">
						<v-icon>mdi-instagram</v-icon>
					</v-btn>
				</v-col>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://facebook.com/oversos" target="_blank">
						<v-icon>mdi-facebook</v-icon>
					</v-btn>
				</v-col>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://twitter.com/oversosofficial" target="_blank">
						<v-icon>mdi-twitter</v-icon>
					</v-btn>
				</v-col>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://linkedin.com/company/oversos" target="_blank">
						<v-icon>mdi-linkedin</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row justify="center" no-gutters>
				<v-col class="py-1 text-center" cols="12"> {{ new Date().getFullYear() }} — &copy; <strong>OverSOS</strong> </v-col>
			</v-row>
		</v-footer>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Sidebar',
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo',
			opened: 'sidebar/opened'
		})
	},
	beforeMount() {
		if (this.$vuetify.breakpoint.mdAndDown & this.opened) {
			this.close()
		} else if (this.$vuetify.breakpoint.lgAndUp & !this.opened) {
			this.open()
		}
	},
	methods: {
		...mapActions('sidebar', ['open', 'close', 'toogle'])
	}
}
</script>
